<template>
  <div>
    <div class="addHospital pb-4">
      <ValidationObserver ref="addFacilityAdmin" v-slot="{ handleSubmit }">
        <form id="addFacilityAdmin" @submit.prevent="handleSubmit(onSubmit)">
          <CCardGroup class="has-loading-overlay">
            <CCard class="p-2">
              <CCardBody>
                <CRow class="row mb-3">
                  <label class="required col-lg-3 col-md-12">Select the type of User</label>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <RadioButton
                      name="user_type"
                      :value="facility.user_type"
                      :options="options && options['user_type'] ? options['user_type'] : []"
                      @change="handleChangeRadio"
                    />
                  </div>
                </CRow>
                <CRow class="row mb-3">
                  <label class="required col-lg-3 col-md-12">Hospital Name</label>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <Select
                        name="organisation_id "
                        :value="facility.organisation_id "
                        @input="handleChangeSelect"
                        :options="options && options['facilityList'] ? options['facilityList'] : []"
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-3">
                  <label class="required col-lg-3 col-md-12">First Name</label>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <TextInput
                        name="first_name"
                        :value="facility.first_name"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-3">
                  <label class="required col-lg-3 col-md-12">Last Name</label>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <TextInput
                        name="last_name"
                        :value="facility.last_name"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-3">
                  <label class="required col-lg-3 col-md-12">Phone</label>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <PhoneInput
                        name="phone"
                        :value="facility.phone"
                        :options="options && options['dialCode'] ? options['dialCode'] : []"
                        label="dialing_code"
                        @change="handleInput"
                        :error="errors[0]"
                        ref="phoneinput"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-3">
                  <label class="required col-lg-3 col-md-12">Email</label>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <ValidationProvider rules="required|email" v-slot="{errors}">
                      <TextInput
                        name="email"
                        :value="facility.email"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-3">
                  <label class="required col-lg-3 col-md-12">Password</label>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <ValidationProvider
                      name="password"
                      :rules="{ required: true, password_length: 8, password_strength: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/ }"
                      v-slot="{errors}"
                    >
                      <TextInput
                        name="password"
                        type="password"
                        :value="facility.password"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                      <small
                        class="hint"
                      >Password format: 8 characters - containing upper & lower case letters, numbers and a special character. No words.</small>
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-3">
                  <label class="required col-lg-3 col-md-12">Confirm Password</label>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <ValidationProvider rules="required|confirmed:password" v-slot="{errors}">
                      <TextInput
                        name="confirmPassword"
                        :value="confirmPassword"
                        @input="handleConfirmPassword"
                        type="password"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCardBody>
            </CCard>
          </CCardGroup>
          <div class="text-center mx-0 mt-4 pt-4 pb-4" style="padding-bottom:150px!important">
            <button
              class="btn rounded-0 px-5 btn-secondary btn-lg mr-2"
              type="button"
              @click="resetFields"
            >Reset</button>
            <button
              class="btn rounded-0 px-5 btn-primary btn-lg"
              type="submit"
              :disabled="isFacilityConfigViolated"
            >Submit</button>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <CModal
      color="danger"
      :show.sync="darkModal"
      v-if="isFacilityDetailsUpdated"
      :close-on-backdrop="false "
    >
      Please update Facility Details.. To add an Admin or User all fields need completing!
      <template #header>
        <h6 class="modal-title">Facility Details</h6>
      </template>
      <template #footer>
        <CButton type="button" color="danger" class="px-4" @click="navToDetails">OK</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import m from "moment";
import { times } from "lodash";
import { extend } from "vee-validate";
import {
  required,
  email,
  confirmed,
  regex,
  min
} from "vee-validate/dist/rules";

import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import RadioButton from "@/components/reusable/Fields/RadioButton";

import { mapGetters, mapActions } from "vuex";
extend("required", { ...required, message: "This field is required" });
extend("email", { ...email, message: "Invalid email" });
extend("password_length", {
  ...min,
  message: "Password be atleast 8 characters"
});
extend("password_strength", {
  ...regex,
  message: "Password must have capitals, numbers and special characters"
});
extend("confirmed", {
  ...confirmed,
  message: "This field should match password"
});
export default {
  components: {
    Select,
    TextInput,
    PhoneInput,
    RadioButton
  },
  computed: {
    ...mapGetters([
      "locationCountryInfo",
      "getCustomerDetails",
      "isFacilityConfigViolated",
      "getCustomerDetailsById",
      "getImpersonationStatus",
      "dialingCodeWithCountryCode",
    ]),
    options() {
      return {
        hospitals: [],
        dialCode: this.dialingCodeWithCountryCode || [],
        user_type: [
          { id: "admin", label: "Admin" },
          { id: "user", label: "User(Facility Recruiter)" }
        ]
      };
    },
    getFacility() {
      return (!this.getImpersonationStatus.impersonation)? this.getCustomerDetails : this.getCustomerDetailsById;
    },
    isFacilityDetailsUpdated() {
      if (Object.keys(this.getFacility).length) {
        if (
          !(
            this.facilities.title_id != null &&
            this.facilities.website &&
            this.facilities.website.length
          )
        ) {
          this.darkModal = true;
          return true;
        }
      }
      this.darkModal = false;
      return false;
    },
    facilities() {
      const facilityData = this.getFacility;
      let facilitySource = {
        facility_id: facilityData.main_organisation_id,
        facility_name: facilityData.name,
        address: facilityData.main_organisation.address,
        website: facilityData.main_organisation.website,
        phone_no: facilityData.main_organisation.phone_no,
        title_id: facilityData.main_contact.title
          ? {
              code: facilityData.main_contact.title.title_id,
              label: facilityData.main_contact.title.title
            }
          : null,
        first_name: facilityData.main_user.first_name,
        last_name: facilityData.main_user.surname,
        email: facilityData.main_contact.email,
        contact_phone_no: facilityData.main_contact.phone_no
      };
      this.facility["organisation_id"] = {
        id: facilityData.main_organisation_id,
        label: facilityData.name
      };
      return facilitySource;
    },
    facilityList() {
      if (Object.keys(!this.getFacility).length) {
        const facilityinfo = this.getFacility;
        return [
          { id: facilityinfo.main_organisation_id, label: facilityinfo.name }
        ];
      }
      return [];
    }
  },
  data() {
    return {
      payload: {},
      facility: {},
      confirmPassword: null,
      darkModal: false,
      isuserTypeChanged: false
    };
  },
  methods: {
    ...mapActions([
      "initFetchFacilityDetails",
      "showToast",
      "checkFacilityUser",
      
    ]),
    handleInput(name, value) {
      Vue.set(this.facility, name, value);
      Vue.set(this.payload, name, value);
    },
    handleChangeSelect(name, value) {
      Vue.set(this.facility, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null
      };
    },
    handleChangeRadio(name, value) {
      let val = null;
      if (value.id === false || value.code === false) {
        val = false;
      } else {
        val = value.id || value.code || value;
      }
      Vue.set(this.facility, name, value);
      this.payload = {
        ...this.payload,
        [name]: val
      };
      this.isuserTypeChanged = true;
      let key = null;
      if (val === "admin") key = "MAX_ADMINS";
      else key = "MAX_RECRUITERS";
      this.checkFacilityUser(key);
      this.resetFields();
    },
    handleConfirmPassword(name, value) {
      this[name] = value;
    },
    async onSubmit() {
      const isValid = await this.$refs.addFacilityAdmin.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!"
        });
        return;
      }
      this.$emit("submit", {
        data: this.payload,
        facility_id: this.facility.organisation_id.id
      });
    },
    navToDetails() {
      this.darkModal = false;
      this.$router.push({ path: `/facility-detail` });
    },
    resetFields() {
      this.facility = { user_type: this.facility.user_type };
      this.confirmPassword=null;
      this.$refs.phoneinput.resetPhoneno();
    }
  },
  created() {
    this.initFetchFacilityDetails((this.getImpersonationStatus.impersonation)?this.getImpersonationStatus.customer_id:null);
  },
  watch: {
    isFacilityConfigViolated() {
      if (this.isFacilityConfigViolated && this.isuserTypeChanged) {
        this.facility.user_type = null;
        this.isuserTypeChanged = false;
        this.resetFields();
        this.showToast({
          class: "bg-danger text-white",
          message: "Maximun Admin / User Reached"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.hint {
  opacity: 0.7;
}
.underline {
  text-decoration: underline;
}
</style>

